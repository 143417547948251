import React from 'react';

const AdminDashboard = () => {
    return (
        <div>
            <h1>Admin felület</h1>
            <p>Fejlesztés alatt</p>
        </div>
    );
};

export default AdminDashboard;