import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { TextField, Button, Box, Typography } from '@mui/material';
import { API_CONFIG } from '../config/api.config';

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Érvénytelen email cím')
        .required('Az email cím megadása kötelező'),
    password: Yup.string()
        .min(8, 'A jelszónak legalább 8 karakter hosszúnak kell lennie')
        .matches(/[A-Z]/, 'A jelszónak tartalmaznia kell legalább egy nagybetűt')
        .matches(/[a-z]/, 'A jelszónak tartalmaznia kell legalább egy kisbetűt')
        .matches(/[0-9]/, 'A jelszónak tartalmaznia kell legalább egy számot')
        .required('A jelszó megadása kötelező'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'A jelszavaknak egyezniük kell')
        .required('A jelszó megerősítése kötelező')
});

const Registration = () => {
    const handleSubmit = async (values, { setSubmitting, setStatus }) => {
        try {
            const response = await axios.post(
                `${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS.register}`,
                { email: values.email, password: values.password }
            );

            setStatus({
                success: true,
                message: 'Sikeres regisztráció! Kérjük, ellenőrizze email fiókját.'
            });
        } catch (error) {
            setStatus({
                success: false,
                message: error.response?.data?.error || 'Hiba történt a regisztráció során'
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box sx={{ maxWidth: 400, margin: '20px auto', padding: '20px' }}>
            <Typography variant="h4" gutterBottom>Regisztráció</Typography>
            <Formik
                initialValues={{ email: '', password: '', confirmPassword: '' }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ errors, touched, status, isSubmitting }) => (
                    <Form>
                        <Field
                            name="email"
                            as={TextField}
                            label="Email cím"
                            fullWidth
                            margin="normal"
                            error={errors.email && touched.email}
                            helperText={errors.email && touched.email ? errors.email : ''}
                        />
                        <Field
                            name="password"
                            as={TextField}
                            type="password"
                            label="Jelszó"
                            fullWidth
                            margin="normal"
                            error={errors.password && touched.password}
                            helperText={errors.password && touched.password ? errors.password : ''}
                        />
                        <Field
                            name="confirmPassword"
                            as={TextField}
                            type="password"
                            label="Jelszó megerősítése"
                            fullWidth
                            margin="normal"
                            error={errors.confirmPassword && touched.confirmPassword}
                            helperText={errors.confirmPassword && touched.confirmPassword ? errors.confirmPassword : ''}
                        />
                        {status && (
                            <Typography
                                color={status.success ? 'success.main' : 'error.main'}
                                sx={{ mt: 2 }}
                            >
                                {status.message}
                            </Typography>
                        )}
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={isSubmitting}
                            sx={{ mt: 2 }}
                        >
                            {isSubmitting ? 'Regisztráció...' : 'Regisztráció'}
                        </Button>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default Registration;