import React from 'react';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import UnderConstruction from './components/UnderConstruction';
import Registration from './components/Registration';
import UserProfile from './components/UserProfile';
import Login from './components/Login';
import AdminDashboard from './components/AdminDashboard';
import Header from './components/Header';
import { AuthProvider } from './context/AuthContext';

const theme = createTheme({
    // Itt definiálhatod a saját témádat
});

const RootLayout = () => {
    return (
        <>
            <Header />
            <Outlet />
        </>
    );
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <RootLayout />,
        children: [
            {
                path: "/",
                element: <UnderConstruction />,
            },
            {
                path: "/registration",
                element: <Registration />,
            },
            {
                path: "/login",
                element: <Login />,
            },
            {
                path: "/admin",
                element: <AdminDashboard />,
            },
            {
                path: "/profile",
                element: <UserProfile />,
            },
        ],
    },
]);

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AuthProvider>
                <RouterProvider router={router} />
            </AuthProvider>
        </ThemeProvider>
    );
}

export default App;