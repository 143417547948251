import React, { useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box, Typography } from '@mui/material';
import { API_CONFIG } from '../config/api.config';

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Érvénytelen email cím')
        .required('Az email cím megadása kötelező'),
    password: Yup.string()
        .required('A jelszó megadása kötelező')
});

const Login = () => {
    const { setUser } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleSubmit = async (values, { setSubmitting, setStatus }) => {
        try {
            const response = await axios.post(
                `${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS.login}`,
                values
            );
            setUser({ id: response.data.userId, token: response.data.token });
            navigate('/');
        } catch (error) {
            setStatus({
                success: false,
                message: error.response?.data?.error || 'Hiba történt a bejelentkezés során'
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box sx={{ maxWidth: 400, margin: '20px auto', padding: '20px' }}>
            <Typography variant="h4" gutterBottom>Bejelentkezés</Typography>
            <Formik
                initialValues={{ email: '', password: '' }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ errors, touched, status, isSubmitting }) => (
                    <Form>
                        <Field
                            name="email"
                            as={TextField}
                            label="Email cím"
                            fullWidth
                            margin="normal"
                            error={errors.email && touched.email}
                            helperText={errors.email && touched.email ? errors.email : ''}
                        />
                        <Field
                            name="password"
                            as={TextField}
                            type="password"
                            label="Jelszó"
                            fullWidth
                            margin="normal"
                            error={errors.password && touched.password}
                            helperText={errors.password && touched.password ? errors.password : ''}
                        />
                        {status && (
                            <Typography color={status.success ? 'success' : 'error'}>
                                {status.message}
                            </Typography>
                        )}
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={isSubmitting}
                            sx={{ mt: 2 }}
                        >
                            {isSubmitting ? 'Bejelentkezés...' : 'Bejelentkezés'}
                        </Button>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default Login;