import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Box
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Header = () => {
    const { user, setUser } = useContext(AuthContext);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleLogout = () => {
        setUser(null);
        setDrawerOpen(false);
    };

    const menuItems = user ? [
        { text: 'Profilom', to: '/profile' },
        { text: 'Kilépés', onClick: handleLogout }
    ] : [
        { text: 'Belépés', to: '/login' },
        { text: 'Regisztráció', to: '/registration' }
    ];

    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Webter Tesztrendszer
                </Typography>

                {user && (
                    <Box sx={{ mr: 2 }}>
                        <Typography variant="body1">{user.email}</Typography>
                    </Box>
                )}

                <IconButton
                    color="inherit"
                    onClick={() => setDrawerOpen(true)}
                    edge="end"
                >
                    <MenuIcon />
                </IconButton>
            </Toolbar>

            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
            >
                <List sx={{ width: 250 }}>
                    {menuItems.map((item) => (
                        <ListItem
                            key={item.text}
                            onClick={() => item.onClick ? item.onClick() : setDrawerOpen(false)}
                            component={item.to ? Link : 'div'}
                            to={item.to}
                            button
                        >
                            <ListItemText primary={item.text} />
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </AppBar>
    );
};

export default Header;