import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import {
    TextField,
    Button,
    Typography,
    CircularProgress,
    Box,
    Alert
} from '@mui/material';

function UserProfile() {
    console.log('UserProfile komponens betöltődött');
    const { user, updateUser } = useContext(AuthContext);
    console.log('User állapot:', user);
    const [formData, setFormData] = useState({
        full_name: '',
        email: '',
        // További mezők szükség szerint
    });
    const [isLoading, setIsLoading] = useState(true);
    const [status, setStatus] = useState(null);

    useEffect(() => {
        const fetchUserProfile = async () => {
            if (!user || !user.token) {
                setIsLoading(false);
                return;
            }

            setIsLoading(true);
            try {
                const response = await axios.get('https://dev.webter.hu/api/profile', {
                    headers: { Authorization: `Bearer ${user.token}` }
                });
                setFormData(response.data);
                updateUser(response.data);
                setStatus(null);
            } catch (error) {
                console.error('Hiba a profil betöltése során:', error);
                setStatus({
                    type: 'error',
                    message: 'Nem sikerült betölteni a profil adatokat.'
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserProfile();
    }, [user?.token]); // Csak a token változására figyeljen

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await axios.put('https://dev.webter.hu/api/profile', formData, {
                headers: { Authorization: `Bearer ${user.token}` }
            });
            updateUser(response.data);
            setStatus({
                type: 'success',
                message: 'A profil sikeresen frissítve.'
            });
        } catch (error) {
            console.error('Hiba a profil frissítése során:', error);
            setStatus({
                type: 'error',
                message: 'Nem sikerült frissíteni a profilt.'
            });
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
            </Box>
        );
    }

    if (!user) {
        return <Typography>Kérjük, jelentkezzen be a profil megtekintéséhez.</Typography>;
    }

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Typography variant="h4" gutterBottom>
                Felhasználói Profil
            </Typography>
            {status && (
                <Alert severity={status.type} sx={{ mb: 2 }}>
                    {status.message}
                </Alert>
            )}
            <TextField
                fullWidth
                margin="normal"
                name="full_name"
                label="Név"
                value={formData.full_name}
                onChange={handleChange}
            />
            <TextField
                fullWidth
                margin="normal"
                name="email"
                label="E-mail cím"
                value={formData.email}
                onChange={handleChange}
            />
            {/* További mezők szükség szerint */}
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={isLoading}
            >
                {isLoading ? 'Frissítés...' : 'Profil Frissítése'}
            </Button>
        </Box>
    );
}

export default UserProfile;